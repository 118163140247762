
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class EditUserModal extends Vue {

    @Prop({
        default: () => new OM.Extraction()
    }) model: OM.Extraction;
    localModel: OM.Extraction = new OM.Extraction();

    newStageDate: string = "";
    newStageLineUp: string = "";

    @Prop() callback: any;

    created() {
        this.localModel = JSON.parse(JSON.stringify(this.model));
    }
    addNewStage() {
        var newStage = new OM.StageDetail();
        newStage.date = this.newStageDate;
        newStage.lineUp = this.newStageLineUp;
        this.localModel.stages.push(newStage);

        this.newStageDate = "";
        this.newStageLineUp = "";
    }
    removeStage(index: number) {
        this.localModel.stages.splice(index, 1);
    }

    save() {
        this.callback(this.localModel);
    }

}
