
import { Options, Vue } from 'vue-class-component';
import { ExtractionClient, InternalUserClient } from '@/services/Services';
import EditEventModal from '../modal/editEventModal.vue';
import * as OM from '@/Model';

@Options({})
export default class Events extends Vue {

    allEvents: OM.Extraction[] = [];

    created() {
        this.init();
    }

    init() {
        ExtractionClient.getAll()
        .then(x => {
            this.allEvents = x;
        })
    }

    getText(notTranslatedText: OM.LocalizedValue) {
        return this.$localizationService.getTextFromValues(notTranslatedText.values);
    }

    editEvent(event: OM.Extraction) {
        if(event == null)
            event = new OM.Extraction();

        this.$opModal.show(EditEventModal, {
            model: event,
            callback: (updatedEvent: OM.Extraction) => {
                ExtractionClient.editExtraction(updatedEvent)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

}
